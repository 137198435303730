import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Clue4 = () => (
  <Layout>
    <Header /> 

    <header className="masthead5">
    <br></br>
    <br></br>   
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #4</h1>
          <br></br> 
          <br></br> 
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            My business provides care
            <br></br>
            but not like you think.
            <br></br>
            <br></br>
            My patients are older
            <br></br>
            quite often they stink.
            <br></br>
            <br></br>
            They do not get their meals
            <br></br>
            from a spoon or a bottle.
            <br></br>
            <br></br>
            But rather prefer
            <br></br>
            to drink via a throttle. 
            <br></br>
            <br></br>
          </h2>
          <br></br>
          <br></br>
          {/* <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
            <a href="/hint4" className="btn btn-primary">
              Hint
            </a> 
          <br></br>
          <br></br>
          <br></br>
          <br></br>          
        </div>
      </div>
    </header>



    {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue4;
